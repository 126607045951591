<template>
  <div id="divPriceListWrapper" class="price-list-container">
    <div class="content-row gray-bg">
      <div class="content-layout-fixer">
        <div class="title-bar">
          <BrandLogo :width="170" :height="170" :image="partner.image" />
          <div class="title-bar-content">
            <ul class="title-bar-icons">
              <li>
                <a class="title-bar-icon" id="title_bar_icon" @click.prevent="printFunc()">
                  <VueIcon
                    :iconName="icon.print.name"
                    :width="icon.print.width"
                    :height="icon.print.height"
                    :iconColor="'#79838e'"
                  ></VueIcon>
                  <VueText
                    class="title-bar-icon-text"
                    :color="'grey-30'"
                    sizeLevel="4"
                    weightLevel="3"
                    >Yazdır</VueText
                  >
                </a>
              </li>
              <li>
                <a class="title-bar-icon" id="title_bar_icon" @click="download()">
                  <VueIcon
                    :iconName="icon.share.name"
                    :width="icon.share.width"
                    :height="icon.share.height"
                    :iconColor="'#79838e'"
                  ></VueIcon>
                  <VueText
                    class="title-bar-icon-text"
                    :color="'grey-30'"
                    sizeLevel="4"
                    weightLevel="3"
                    >Kaydet</VueText
                  >
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="content-row">
      <div class="content-layout-fixer">
        <BrandProductPriceToolbar partner="PMI" :routes="routes" :active-route="routes.priceList" />
      </div>
    </div>
    <div class="content-row">
      <div class="content-layout-fixer">
        <div class="pl-img-wrapper" id="priceList">
          <img :src="imageUrl" alt="price list" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BrandProductPriceToolbar from '@/components/brand/BrandProductPriceToolbar/BrandProductPriceToolbar.vue';
import RoutesPmi from '@/router/routes/secure/businessPartners/RoutesPmi';
import VueText from '@/components/shared/VueText/VueText.vue';
import VueIcon from '@/components/shared/VueIcon/VueIcon.vue';
import { ICON_VARIABLES } from '@/constants/component.constants';
import productApi from '@/services/Api/product';
import DownloadFile from '@/utils/downloadFile.js';
import BrandLogo from '@/components/brand/BrandLogo/BrandLogo.vue';
export default {
  name: 'PriceList',
  data() {
    return {
      partner: {},
      pageInfo: '',
      imageUrl: '',
    };
  },
  computed: {
    icon() {
      return {
        share: ICON_VARIABLES.share,
        print: ICON_VARIABLES.print,
      };
    },
    routes() {
      return {
        priceList: `${RoutesPmi.PriceList.path}`,
        productList: `${RoutesPmi.ProductList.path}`,
      };
    },
  },
  beforeMount() {
    productApi.getPriceList().then(res => {
      if (res && res.data) {
        const { Data } = res.data;

        this.pageInfo = Data.pageInfo;
        this.imageUrl = Data.imageUrl;
        this.partner = Data.partner;
      }
    });
  },
  components: {
    BrandProductPriceToolbar,
    VueText,
    VueIcon,
    BrandLogo,
  },
  methods: {
    printFunc() {
      window.print();
    },
    download() {
      new DownloadFile().download('fiyat-listesi', this.imageUrl, 'otherList');
    },
  },
};
</script>
<style lang="scss" scoped>
@mixin flex-center {
  display: flex;
  align-items: center;
}
.price-list-container {
  .content-row {
    height: unset;
  }
  .gray-bg {
    background: #f8f9f9;
    margin-bottom: 60px;
    padding: 40px 0;
  }
  /deep/ .crumbs-holder {
    padding-left: 0;
  }
  .price-list-toolbar {
    padding: palette-space-level(20);
  }
  .actions {
    a {
      width: inherit;
    }
  }
  .title-bar {
    @include flex-center;
    margin-bottom: palette-space-level(5);
    .title-bar-content {
      @include flex-center;
      position: relative;
      width: 100%;
      justify-content: space-between;
      padding-left: palette-space-level(10);
      padding-right: palette-space-level(10);
      .title-text {
        width: 400px;
      }
    }
    ul.title-bar-icons {
      position: absolute;
      right: 0;
      list-style-type: none;
      padding: 0;
      margin: 0 15px 0 0;
      li {
        float: left;
        margin-left: palette-space-level(30);
        cursor: pointer;
        a {
          .title-bar-icon-text {
            display: inline-block;
          }
        }
        .title-bar-icon {
          svg {
            position: relative;
            top: 2px;
            width: 18px;
            height: 18px;
            margin-right: palette-space-level(10);
          }
        }
      }
    }
  }
  .pl-img-wrapper {
    text-align: center;
    margin-bottom: palette-space-level(30);
  }
}
</style>

<style>
@media print {
  @page {
    margin: 0;
  }
  body * {
    visibility: hidden;
  }
  #priceList * {
    visibility: visible;
  }
  #priceList {
    position: absolute;
    top: 40px;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
